// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10087(064ffedb15)-C02/04/2024-12:04:57-B02/04/2024-12:16:59' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10087(064ffedb15)-C02/04/2024-12:04:57-B02/04/2024-12:16:59",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10087",
  shortHash: "064ffedb15",
  longHash: "064ffedb15b81262d095b53b6d4d3bac782cd0e0",
  dateCommit: "02/04/2024-12:04:57",
  dateBuild: "02/04/2024-12:16:59",
  buildType: "Ansible",
  } ;
